import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AppService } from "../app.service";
import { UserService } from "../user.service";

@Component({
	selector: "cm-header",
	template: `
		<header id="nav-header" class="header shadow bg-white">
			<div class="container-fluid logo-bg">
				<div class="row align-items-stretch" itemscope itemtype="http://schema.org/WPHeader">
					<div class="col-6 col-lg-2 d-flex justify-content-center py-2">
						<a routerLink="/">
							<img
								[src]="app.logo$ | async | image : 's'"
								alt="{{ app.dealer$ | async }}"
								class="logo img-fluid"
							/>
						</a>
					</div>
					<div class="col-6 col-lg-10 d-flex flex-column justify-content-center">
						<div class="row bg-secondary text-white d-none d-lg-block align-items-center py-2 flex-grow-1">
							<div
								class="col-12 h6 font-weight-normal m-0 h-100 d-flex justify-content-start align-items-center"
							>
								<a href="tel:{{ app.phoneNumber$ | async }}" class="text-white mx-1"
									><img
										src="https://dms.rvimg.com/s3static/12/icon-phone-white.png"
										alt="phone"
										class="img-fluid mx-2"
									/>{{ app.phoneNumber$ | async }}</a
								>
								<a [href]="app.addressLink$ | async" class="address text-white mx-2">
									<img
										src="https://dms.rvimg.com/s3static/12/icon-pin-white.png"
										alt="pin"
										class="img-fluid mx-1"
									/>
									{{ app.addressLine1$ | async }} {{ app.addressLine2$ | async }}
								</a>
								<span class="mx-2"
									><img
										src="https://dms.rvimg.com/s3static/12/icon-hours-white.png"
										alt="clock"
										class="img-fluid mx-1" /><cm-hours></cm-hours
								></span>
								<div class="flex-grow-1 text-right">
									<button
										*ngIf="!(userService.loggedIn$ | async)"
										type="button"
										class="btn btn-sm btn-secondary rounded-0 mr-2"
										(click)="showLogin = true"
									>
										Log In
									</button>
								</div>
							</div>
						</div>
						<div class="row">
							<div
								class="col-12 col-lg-8 d-flex align-items-center justify-content-end justify-content-lg-start pl-0 pr-1 menu-holder"
							>
								<cm-menu2 [appMenuid]="1" rootStyle="inline" subStyle="unstyled"></cm-menu2>
							</div>
							<div
								class="d-none d-lg-flex flex-column align-items-end justify-content-center col-lg-4 px-1"
							>
								<a
									routerLink="/contact"
									[queryParams]="{ activeId: 'scheduleService' }"
									class="btn btn-warning text-black rounded-0 d-flex flex-wrap align-items-center justify-content-center"
									>SCHEDULE SERVICE
									<img
										loading="lazy"
										src="https://dms.rvimg.com/s3static/50/button-arrow-black.png"
										class="img-fluid"
										style="width:50px;"
										alt="arrow"
								/></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<cm-login-modal [(show)]="showLogin" (loggedIn)="showLogin = false"></cm-login-modal>
	`,
	styleUrls: ["./header.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
	showLogin: boolean = false;
	constructor(public app: AppService, public userService: UserService) {}
}
