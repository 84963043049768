import { Component } from "@angular/core";
import { AppService } from "@core/app/app.service";
import { UserService } from "@core/app/user.service";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

@Component({
	selector: "cm-footer",
	template: `
		<div class="container-fluid py-3 p-lg-5 footer-bg">
			<div class="row align-items-center justify-content-center mx-auto">
				<div class="col-12 col-xl-2 col-sm-6 d-flex py-2 justify-content-center">
					<a routerLink="/">
						<img
							[src]="app.logo$ | async | image: 'm'"
							alt="{{ app.dealer$ | async }}"
							class="logo img-fluid"
						/>
					</a>
				</div>
				<div class="col-12 col-xl-4 col-sm-6 text-center">
					<div class="d-inline-block mx-auto text-left">
						<div class="row align-items-center my-2">
							<div class="col-auto flex-grow-0">
								<img
									src="https://dms.rvimg.com/s3static/50/icon-phone-white.png"
									alt="phone"
									class="img-fluid"
									width="35px"
								/>
							</div>
							<div class="col-auto flex-grow-1">
								<a
									href="tel:{{ app.phoneNumber$ | async }}"
									class="text-white h5 font-weight-normal mb-0"
									>{{ app.phoneNumber$ | async }}</a
								>
							</div>
						</div>
						<div class="row align-items-center my-2">
							<div class="col-auto flex-grow-0">
								<img
									src="https://dms.rvimg.com/s3static/50/icon-pin-white.png"
									alt="pin"
									class="img-fluid"
									width="35px"
								/>
							</div>
							<div class="col-auto">
								<a
									[href]="app.addressLink$ | async"
									class="address text-white h5 font-weight-normal mb-0"
								>
									{{ app.addressLine1$ | async }}<br />{{ app.addressLine2$ | async }}
								</a>
							</div>
						</div>
						<div class="row align-items-center my-2">
							<div class="col-auto flex-grow-0">
								<img
									src="https://dms.rvimg.com/s3static/50/icon-hours-white.png"
									alt="clock"
									class="img-fluid"
									width="35px"
								/>
							</div>
							<div class="col-auto">
								<cm-hours class="text-white h5 font-weight-normal mb-0"></cm-hours>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-xl-5">
					<div class="row flex-wrap justify-content-around justify-content-xl-start">
						<div
							class="col-12 col-sm-4 text-center text-xl-left d-flex flex-column justify-content-between"
						>
							<a routerLink="/services" class="h4 font-weight-bold text-white">Services</a>
							<a routerLink="/services" fragment="landscaping" class="h6 font-weight-bold text-white"
								>Landscaping</a
							>
							<a routerLink="/services" fragment="retaining-walls" class="h6 font-weight-bold text-white"
								>Retaining Walls</a
							>
							<a routerLink="/services" fragment="patios" class="h6 font-weight-bold text-white"
								>Patios and Walkways</a
							>
							<a routerLink="/services" fragment="irrigation" class="h6 font-weight-bold text-white"
								>Irrigation</a
							>
							<a routerLink="/services" fragment="lawn-maintenance" class="h6 font-weight-bold text-white"
								>Lawn Maintenance</a
							>
							<a routerLink="/services" fragment="hydroseeding" class="h6 font-weight-bold text-white"
								>Hydroseeding</a
							>
							<a routerLink="/services" fragment="snow-removal" class="h6 font-weight-bold text-white"
								>Snow Removal</a
							>
						</div>
						<div
							class="col-12 col-sm-4 text-center text-xl-left d-flex flex-column justify-content-between"
						>
							<a routerLink="/products" class="h4 font-weight-bold text-white">Selections</a>
							<a routerLink="/category/pavers" class="h6 font-weight-bold text-white">Pavers</a>
							<a routerLink="/category/permeable-pavers" class="h6 font-weight-bold text-white"
								>Permeable Pavers</a
							>
							<a routerLink="/category/porcelain-tile" class="h6 font-weight-bold text-white"
								>Porcelain Tile</a
							>
							<a routerLink="/category/fireplaces-grills" class="h6 font-weight-bold text-white"
								>Fireplaces & Grills</a
							>
							<a routerLink="/category/walls" class="h6 font-weight-bold text-white">Walls</a>
							<a routerLink="/category/coping" class="h6 font-weight-bold text-white">Coping</a>
							<a routerLink="/category/pillar-cap" class="h6 font-weight-bold text-white">Pillar Cap</a>
							<a routerLink="/category/steps" class="h6 font-weight-bold text-white">Steps</a>
						</div>
						<div class="col-12 col-sm-4 text-center text-xl-left d-flex flex-column justify-content-center">
							<a routerLink="/about-us" class="h4 font-weight-bold text-white my-3">About Us</a>
							<a routerLink="/contact" class="h4 font-weight-bold text-white my-3">Contact</a>
							<div class="my-3">
								<a
									href="https://www.facebook.com/mikeroselandscaping/"
									class="h4 font-weight-bold text-white mx-1"
									target="_blank"
									><fa-icon [icon]="faFacebook" [fixedWidth]="true"></fa-icon
								></a>
								<a
									href="https://www.instagram.com/mikeroselandscaping/"
									class="h4 font-weight-bold text-white mx-1"
									target="_blank"
									><fa-icon [icon]="faInstagram" [fixedWidth]="true"></fa-icon
								></a>
							</div>
							<button
								*ngIf="!(userService.loggedIn$ | async)"
								type="button"
								class="btn btn-sm btn-secondary rounded-0 mr-2"
								(click)="showLogin = true"
							>
								Log In
							</button>
						</div>
					</div>
				</div>
				<div class="col-12 text-white my-1">
					&copy;{{ year }} {{ app.dealer$ | async }} |
					<a class="text-white" routerLink="/terms-of-service">Terms of Service</a> |
					<a class="text-white" routerLink="/privacy-policy">Privacy Policy</a> | Powered by
					<a href="//designforcemarketing.com" class="text-white" target="_blank">Design Force Marketing</a>
				</div>
			</div>
		</div>
		<cm-login-modal [(show)]="showLogin" (loggedIn)="showLogin = false"></cm-login-modal>
	`,
	styles: [
		`
			:host {
				display: block;
			}
			.footer-bg {
				background: linear-gradient(rgba(124, 176, 65, 0.7), rgba(124, 176, 65, 0.7)),
					url(//dms.rvimg.com/s3static/12/footer-bg-1.webp);
				background-size: cover;
				background-repeat: no-repeat;
				background-position: right bottom;
			}
		`,
	],
})
export class FooterComponent {
	year = new Date().getFullYear();
	faFacebook = faFacebook;
	faInstagram = faInstagram;
	showLogin: boolean = false;

	constructor(public app: AppService, public userService: UserService) {}
}
